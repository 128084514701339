export default {
  login: {
    or: 'Or',
    aGameBy: 'A game by',
    form: {
      validation: {
        email: {
          required: 'Email is required'
        },
        password: {
          required: 'Password is required'
        }
      }
    }
  },
  assignment: {
    card: {
      startsAt: 'Starts at: <strong>{{ date }}</strong>',
      endsAt: 'Ends at: <strong>{{ date }}</strong>'
    },
    emptyContent: {
      title: 'No results to show here',
      description:
        'Try to clear the filters clicking below or wait until we add more games',
      noStudents: 'No students'
    },
    tabs: {
      list: 'List',
      calendar: 'Calendar'
    },
    dataList: {
      header: {
        title: 'Title',
        status: 'Status',
        startDate: 'Start date',
        endDate: 'End date',
        assignedTo: 'Assigned to',
        progress: 'Progress',
        actions: 'Actions'
      },
      displayHeader: {
        scoreMeaning: 'What score mean?',
        statusColorMeaning: 'Status - color meanings'
      },
      cell: {
        numberOfStudents_zero: 'No student',
        numberOfStudents_one: '{{ count }} student',
        numberOfStudents_other: '{{ count }} students',
        modals: {
          delete: {
            title: 'Delete assignment',
            content:
              'This will delete <strong>{{ assignmentName }}</strong> from your assignments list.<br/>The report for <strong>{{ assignmentName }}</strong> will also be deleted.'
          }
        }
      }
    },
    form: {
      assignment: 'assignment',
      topic: 'topic',
      student: 'student',
      errors: {
        assignmentTitleIsRequired: 'Please, type an assignment name',
        fieldRequired:
          'You have <strong>not</strong> selected any {{ field }}s for this assignment. In order to save this assignment you need to select at least one {{ field }}.',
        noElementsSelected: 'No {{ elements }} selected',
        selectStudentOrGroups: 'Students/groups must be added',
        assignToIsRequired:
          '​Please select a student or a group with students.​​'
      }
    },
    games: {
      emptyContent: {
        title: 'You have not added any missions',
        description: 'Press the button in the right corner to add a mission.'
      }
    }
  },
  group: {
    header: {
      createAssignment: 'Create assignment',
      groupName: 'Group name',
      hiddenGroups: 'Hidden groups',
      showingAllHiddenGroups: 'Showing all <strong>hidden groups</strong>'
    },
    table: {
      header: {
        name: 'Group name',
        studentCount: 'Number of students',
        students: 'Students',
        hiddenGroups: 'Hidden groups'
      },
      emptyContent: {
        general: {
          title: 'No hidden groups',
          subtitle: 'If you hide a Feide group you will find it here.'
        },
        landscape: {
          title: 'Find students you want to add to the group.',
          subtitle: 'You do this from the list on the left.'
        },
        portrait: {
          title: 'Find students you want to add to the group.',
          subtitle: 'You do this from the list above.'
        }
      },
      content: {
        add: 'Add',
        more: '{{ count }} more',
        students_zero: 'No student',
        students_one: '{{ count }} student',
        students_other: '{{ count }} students',
        studentPlaceholderDraggingOver: 'Drop here to add student',
        groupsPlaceholder: 'Drop students here'
      }
    },
    emptyContent: {
      title: 'You don’t have any groups created yet',
      description: 'Click below to create groups and organize your students',
      mobileDescription: 'Change to desktop to create groups',
      hiddenGroup: {
        title: 'No hidden groups',
        description: 'If you hide a Feide group you will find it here.'
      }
    },
    modal: {
      table: {
        name: 'Name',
        groups: 'Groups'
      }
    },
    sideBar: {
      title: 'Students',
      description:
        'You can drag the students here to <strong>Groups</strong>.<br/>Select multiple to drag them all at once.',
      studentsAdded: 'students added',
      feedback: {
        success: 'Awesome, you have created this group'
      },
      search: {
        header: {
          placeholder: 'Search students...'
        },
        footer: {
          placeholder: 'Enter a group name'
        }
      },
      button: {
        add: 'Add'
      },
      openStudents: 'Open students',
      closeStudents: 'Close students'
    },
    warning: {
      userAddedToGroup: '{{ user }} added to {{ group }}',
      deleteGroup: 'Permanently delete group?',
      showingGroup: 'Your group  has been shown.',
      hidingGroup: 'Your group  has been hidden.'
    }
  },
  content: {
    addTopic: 'Add topic',
    estimatedPlayTime: 'Estimated play time',
    selectedItems: 'Selected items',
    youHave: 'You have ',
    itemsAdded: 'items added',
    actions: {
      backToList: 'Back to list',
      continue: 'Continue'
    },
    form: {
      assignment: 'Assignment Title',
      assignmentPlaceholder: 'Class activity #01',
      period: 'Period',
      time: 'Time',
      assignedTo: 'Assigning to',
      addMore: 'Add more students',
      addStudents: 'Add students',
      students_one: '<strong>{{ count }} student</strong>',
      students_other: '<strong>{{ count }} students</strong>',
      assignTo: 'Assign to',
      lock: 'Lock',
      disabledGames:
        'You have added <strong>{{ disabledGamesCount }} games</strong> that were disabled, please remove them.',
      lockWarning:
        'Use the lock function if you want the students to complete locked assignments before they get access to the rest of the game.'
    },
    details: {
      title: 'Assignment details',
      subtitle: 'See the assignment details below',
      topicsAdded: 'My chosen missions',
      addNewMission: 'Add a mission',
      missionsAdded: 'My chosen missions',
      assignBtnHint:
        'Add students, groups or classes who will receive this assignment by pressing the button marked “Add students” below. ',
      noTopicsAdded: 'No topics'
    }
  },
  reports: {
    actions: {
      seeStudents: 'See students',
      seeDetails: 'See details'
    },
    descriptions: {
      overallResults: 'Overall results',
      listOfResultsFrom: 'This is the list of results from {{ from }}',
      resultsFromEachMissionIn: 'Results from each mission in {{ in }}',
      resultsForAllStudents: 'Results for all Students',
      resultsFor: 'Results for {{ for }}',
      studentDetails: 'Student details',
      students: 'Students',
      student: 'Student',
      assignments: 'Assignments',
      assignment: 'Assignment',
      missions: 'Missions',
      thisIsTheListOfResultsFromGameMode:
        'This is the list of results from {{ gameModeName }}',
      thisIsTheListOfStudentsFrom:
        'This is the list of student results from {{ from }}',
      thisIsTheListOfResultsFromIn:
        'This is the list of results from {{ from }} in {{ in }}',
      noReportFound: 'No reports found',
      tryToSelectAnotherReport: 'Try to select another report'
    },
    table: {
      headers: {
        avgScore: {
          help: 'A high score (up to 1000) means that the student has had several correct answers in a row. A low score (down to 500) means that the student answered incorrectly before they answered correctly.'
        }
      }
    },
    tabs: {
      assignments: {
        allAssignments: 'All assignments',
        showingAssignments: 'Showing all assignments',
        showingAssignments_filtered:
          'Showing assignments in <strong>{{ in }}</strong>'
      },
      students: {
        allStudents: 'All students',
        showingStudents: 'Showing all students',
        showingStudents_filtered:
          'Showing students in <strong>{{ in }}</strong>'
      }
    }
  },

  report: {
    dataList: {
      titles: {
        resultsFromWholeAddition:
          'Showing results from the whole Addition training group',
        assignmentNameAndGameModeName:
          '{{ assignmentName }} - {{ gameModeName }}',
        resultsFromAllStudents: 'Showing results from all students'
      },
      help: {
        whatTheScoreMeans: `
        A high score (up to 1000) means that the student has had several correct answers in a row. A low score (down to 500) means that the student answered incorrectly before they answered correctly.
        `
      },
      header: {
        name: 'Name',
        endDate: 'End date',
        startDate: 'Start date',
        groups: 'Group(s)',
        score: 'Score',
        avgScore: 'Score (avg)',
        timeSpent: 'Time spent',
        totalTimeSpent: 'Total time spent',
        totalFailedAnswers: 'Total failed answers',
        playedLevels: 'Levels played',
        completedLevels: 'Completed levels',
        assignment: 'Assignment',
        totalScore: 'Total Score',
        avgTimeSpent: 'Time spent (avg)',
        failedAnswers: 'Failed answers',
        avgFailedAnswers: 'Failed answers (avg)',
        progress: 'Progress',
        game: 'Game',
        student: 'Student',
        missionAndModes: 'Mission and modes',
        popover: {
          title: 'What {{ field }} mean?',
          description:
            'The score is calculated based on the number of correct answers and time spent. 1000 points means that a student has gotten all answers correct and completed them quickly. A lower score is assessed based on time spent and/or the number of wrong answers.'
        }
      }
    },
    menu: {
      autoCompletePlaceholder: 'Search students',
      buttons: {
        print: 'Print',
        assignment: {
          default: 'All assignments',
          mobileTitle: 'Select an assignment'
        },
        eye: {
          showStudents: 'Show students',
          hideStudents: 'Hide students',
          browseByStudents: 'Browse by students'
        }
      }
    },
    sideBar: {
      filterSelectPlaceholderClosed: 'Filter students by groups',
      filterSelectPlaceholderOpen: 'Select a group',
      wholeClass: 'Whole Class',
      typeToSearch: 'Type to search...',
      browseByStudent: 'Browse by student'
    }
  },
  license: {
    schoolList: {
      dataList: {
        header: {
          schoolName: 'School name',
          assignedLicenses: 'Licenses assigned',
          expiryDate: 'Expiry date'
        },
        manageSchool: 'Manage school'
      }
    },
    schoolView: {
      teachers: {
        title: 'Teachers',
        card: {
          numberOfTeachers: '<strong>{{ count }}</strong> Teachers',
          numberOfActivatedLicenses:
            '<strong>{{ count }}</strong> activated licenses'
        },
        manageTeachers: 'Manage teachers'
      },
      classes: {
        title: 'Classes',
        header: {
          className: 'Class name',
          numberOfStudents: 'Number of students',
          licensesAssigned: 'Licenses assigned'
        },
        assignClass: 'Assign to whole class',
        revokeClass: 'Revoke all licenses'
      }
    },
    teacherListTable: {
      header: {
        teacherName: 'Teacher name',
        accessActivated: 'Access activated'
      },
      activateAccess: 'Activate access',
      revokeAccess: 'Revoke access'
    },
    studentListTable: {
      header: {
        studentName: 'Student name',
        accessActivated: 'Access activated',
        assignClass: 'Assign to whole class',
        revokeClass: 'Revoke all licenses'
      },
      assignLicense: 'Assign license',
      revokeLicense: 'Revoke license '
    },
    header: {
      content: {
        totalOfLicenses: '<strong>Total of licenses:</strong> {{ value }}',
        licensesAssigned: '<strong>Licenses assigned:</strong> {{ value }}',
        balance: '<strong>Balance:</strong> {{ value }}'
      }
    }
  },
  offlineContent: {
    table: {
      assignment: 'Assignment',
      subject: 'Subject',
      downloadFile: 'Download file'
    }
  },
  pdfGenerator: {
    generators: {
      multiplication: {
        title: 'Multiplication',
        subtitle: 'Create a worksheets'
      },
      additionAndSubtraction: {
        title: 'Addition & Subtraction',
        subtitle: 'Create worksheets',
        addition: {
          title: 'Addition',
          subtitle: 'Under Development'
        },
        subtraction: {
          title: 'Subtraction',
          subtitle: 'Under Development'
        }
      },
      division: {
        title: 'Division',
        subtitle: 'Create worksheets'
      },
      fraction: {
        title: 'Fraction',
        subtitle: 'Under Development'
      }
    },
    subViews: {
      generate: {
        settings: 'Settings',
        preview: 'Preview',
        summary: {
          title: 'My Choices'
        },
        buttons: {
          cancel: 'Cancel',
          clearOptions: 'Clear Options',
          createWorksheet: 'Create Worksheet'
        },
        tasksPerSheet: {
          title: 'Tasks per sheet',
          label: '{{ numberOfTasks }} tasks',
          summary: 'Tasks per sheet: {{ numberOfTasks }} tasks'
        },
        shuffleEqualsSign: {
          title: 'Shuffle equal sign placement'
        },
        generateAnswerSheet: {
          title: 'Include answer key'
        },
        numberOfWorkSheets: {
          title: 'Number of worksheets',
          helperText:
            'Each worksheet will contain different exercises based on your chosen settings.'
        },
        generateDifferentExercises: {
          title:
            'More worksheets with new exercises (choose 2 number of sheets in “My choices”)'
        },
        randomizeMissingFactorProduct: {
          title: 'Randomize missing term'
        },
        range: {
          from: {
            label: 'From:',
            validation: {
              integer: '"From" number must be a whole number.'
            }
          },
          to: {
            label: 'To:',
            validation: {
              min: '"To" has to be bigger than or equal to the number in the "From" field.',
              integer: '"To" number must be a whole number.'
            }
          },
          options: {
            option_1: 'None',
            option_10: 'Whole tens',
            option_100: 'Whole hundreds',
            title: 'Extra options:',
            summary: 'Extra option: {{ option }}'
          },
          firstFactor: {
            title: 'Number of digits (1st term)',
            summary: 'Number range (First Factor): {{ from }} to {{ to }}'
          },
          secondFactor: {
            title: 'Number of digits (2nd term)',
            summary: 'Number range (Second Factor): {{ from }} to {{ to }}'
          }
        },
        operations: {
          multiplication: {
            randomizeOrderOfFactors: {
              title: 'Randomize order of terms'
            }
          },
          division: {
            randomizeMissingFactorProduct: {
              title: 'Randomize missing term'
            }
          },
          addictionAndSubtraction: {
            arithmeticOperation: {
              title: 'Arithmetic operation',
              validation: {
                operationsCantBeFalse: `Addition and Subtraction mustn't both be false`
              }
            },
            horizontalOperation: {
              title: 'Horizontal method'
            },
            verticalOperation: {
              title: 'Column method'
            }
          }
        }
      },
      download: {
        loadingText: 'Please wait, the worksheet is being generated.',
        title: 'Your worksheet is ready for download',
        fileName: '{{ operation }}-worksheet.pdf',
        buttons: {
          download: 'Download',
          generateAnotherWorksheet: 'Create another worksheet'
        },
        info: 'This option takes you back to the generator keeping your previous settings.',
        createNewWorksheet: 'Create a new worksheet'
      }
    }
  },
  customerOverview: {
    dataList: {
      headers: {
        customerId: 'Customer ID',
        organization: 'Organization',
        municipality: 'Municipality',
        dealType: 'Deal Type',
        premiumStatus: 'Premium Status',
        actions: 'Actions'
      },
      content: {
        municipality: 'Municipality',
        school: 'School',
        manage: 'Manage',
        premium: `Premium`,
        freemium: `Freemium`
      }
    },
    filters: {
      municipality: 'Municipality'
    },
    forms: {
      create: 'Create',
      addLicenseManager: 'Add license manager',
      primaryLicenseManager: 'Primary license manager',
      secondaryLicenseManager: 'Secondary license manager'
    },
    tabs: {
      customerOverview: {
        title: 'Customer Overview',
        content: {
          customerId: {
            label: 'Customer ID',
            placeholder: 'Enter customer ID'
          },
          intercomUserId: {
            label: 'Intercom user ID',
            placeholder: 'Enter intercom user ID'
          },
          dealType: {
            label: 'Deal Type',
            placeholder: 'Enter deal type'
          },
          dealOwner: {
            label: 'Deal Owner',
            placeholder: 'Enter deal owner'
          },
          jobTitle: {
            label: 'Job title',
            placeholder: 'Enter job title'
          },
          role: {
            label: 'Role',
            placeholder: 'Enter role'
          },
          organizationFeideId: {
            label: 'Organization',
            placeholder: 'Enter organization Feide ID'
          },
          managerName: {
            label: 'Manager name',
            placeholder: 'Enter Account Manager'
          },
          phoneNumber: {
            label: 'Phone number',
            placeholder: 'Enter phone number'
          },
          email: {
            label: 'Email',
            placeholder: 'Enter E-mail'
          },
          municipality: {
            label: 'Municipality',
            placeholder: 'Enter municipality'
          },
          address: {
            label: 'Address',
            placeholder: 'Enter address'
          },
          product: {
            label: 'Product',
            placeholder: 'Enter product'
          },
          discount: {
            label: 'Discount',
            placeholder: 'Enter discount'
          },
          numberOfLicenses: {
            label: 'Number of licenses',
            placeholder: 'Enter number of licenses'
          },
          pricePerLicense: {
            label: 'Price per license',
            placeholder: 'Enter price per license'
          },
          dealValue: {
            label: 'Deal value',
            placeholder: 'Enter deal value'
          },
          balance: {
            label: 'License Bank Balance',
            placeholder: 'Enter License bank'
          },
          status: {
            label: 'Status',
            placeholder: 'Select Customer Status'
          },
          isPayPerLogin: {
            label: 'Pay-Per-Login',
            checkbox: {
              enable: 'Enable'
            }
          },
          isTrial: {
            label: 'Is Trial?',
            checkbox: {
              enable: 'Enable'
            }
          },
          dateEndTrial: {
            label: 'Date End of Trial',
            placeholder: 'Enter date end of trial'
          },
          endContract: {
            label: 'Date End of Contract',
            placeholder: 'Enter date end of contract'
          }
        },
        footer: {
          submit: '{{ action }} Customer'
        },
        modal: {
          create: {
            alert: {
              success: 'The customer was successfully created'
            }
          },
          update: {
            alert: {
              success: 'The customer was successfully edited'
            }
          }
        }
      },
      invoice: {
        title: 'Invoice',
        content: {
          status: {
            label: 'Status',
            placeholder: 'Select invoice status'
          },
          plan: {
            label: 'Plan',
            placeholder: 'Enter Plan'
          },
          file: {
            label: 'File',
            placeholder: 'Enter File'
          },
          date: {
            label: 'Date',
            placeholder: 'Enter Date'
          },
          duration: {
            label: 'Duration',
            placeholder: 'Enter Deal duration'
          },
          total: {
            label: 'Total',
            placeholder: 'Enter Total'
          },
          renewalDate: {
            label: 'Renewal Date',
            placeholder: 'Enter Renewal Date'
          }
        },
        footer: {
          submit: '{{ action }} Invoice'
        },
        modal: {
          create: {
            alert: {
              success: 'The invoice was successfully created'
            }
          }
        }
      },
      invoiceHistory: {
        title: 'Invoice History',
        dataList: {
          actions: {
            title: 'Actions',
            content: {
              viewPdf: 'View PDF',
              download: 'Download'
            }
          }
        }
      },
      license: {
        title: 'License',
        dataList: {
          role: {
            title: 'Role',
            content: {
              placeholder: 'Enter role'
            }
          },
          managerName: {
            title: 'Manager name',
            content: {
              placeholder: 'Enter manager name'
            }
          },
          managerFeideId: {
            title: 'Feide ID',
            content: {
              placeholder: 'Feide ID'
            }
          },
          schoolName: {
            title: 'School name',
            content: {
              placeholder: 'Enter school name'
            }
          },
          numberOfLicenses: {
            title: 'Number of licenses',
            content: {
              placeholder: 'Nr. of licenses'
            }
          },
          actions: {
            title: 'Actions'
          }
        },
        addLicenseManager: 'Add License manager',
        footer: {
          submit: '{{ action }} License'
        },
        modal: {
          update: {
            alert: {
              success: 'The invoice was successfully edited'
            }
          }
        }
      }
    },
    placeholder: {
      enterName: 'Enter name',
      schoolName: 'School name',
      numberOfLicenses: 'Nr. of Licenses'
    }
  },
  support: {
    items: {
      talkToUs: {
        title: 'Talk to us',
        description:
          'Customer service is available every weekday from 8:00 a.m. to 4:00 p.m.<br/> Write to us in the chat in the bottom right corner or send an e-mail to <strong>hei@numetry.no</strong>.'
      },
      seeOurQuickGuides: {
        title: 'See our quick guides'
      },
      faq: {
        title: 'Frequently asked questions',
        description:
          "In [Numetry's Help Center](https://intercom-help.eu/numetrygame/nb/) you will find a number of articles with answers to frequently asked questions."
      },
      userManual: {
        title: 'User manual',
        description:
          'Access/download the <strong>user manual</strong> to learn about all Numetry features and services. It can also be printed.'
      },
      join: {
        title: 'Join the community',
        description:
          'Join the Facebook page [Mathematics game Numetry for teachers](https://www.facebook.com/groups/1039314306594631/) to talk to other teachers who use<br/> Numetry Skole, get access to the latest news and help influence development.'
      },
      privacyStatement: {
        title: '[Privacy statement](https://www.numetrygame.com/personvern/)'
      },
      termsOfUse: {
        title:
          '[Terms of use](https://www.numetrygame.com/vilkar-og-betingelser/)'
      }
    },
    articles: {
      howToUseReports: {
        title: 'How to use reports'
      },
      howToCreateAnAssignment: {
        title: 'How to create an assignment'
      },
      numetryInTeaching: {
        title: 'Class Activities with Numetry'
      },
      tipsForLessons: {
        title: 'How to create a group'
      }
    }
  }
}
