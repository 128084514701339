import { NavigationLocaleTypes } from '../locales.types'

const navigationLocaleNo: NavigationLocaleTypes = {
  mainMenu: {
    title: 'Hovudmeny',
    titleMobile: 'Meir'
  },
  menu: {
    title: 'Meny'
  },
  assignments: {
    title: 'Mine oppgaver​',
    subtitle:
      'Her visast ein oversikt over alle oppgåvene du har oppretta. Statusen viser om oppgåva er planlagt, aktiv eller utgått. Ei planlagt oppgåve er enda ikkje synleg i oppdragstavla i spelet. Du kan søke etter namn på oppgåver, filtrere etter status og sortere ut frå kolonnane. Trykk «Rediger» for å gjøre endringer på en oppgave.',
    newTitle: 'Lag en ny oppgave',
    components: {
      contentModal: {
        subtitle:
          'Velg nye oppdrag å legge til i oppgaven. Finn ut mer om hvert spill ved å klikke på <strong>«Mer info»</strong> på kortet. Du kan også begynne å lage en oppgave ved å velge spill nedenfor.'
      }
    },
    create: {
      title: 'Lag en oppgave',
      subtitle:
        'Ei oppgåve er ei samling av matteoppdrag som du deler ut til elevane med det som skal vere fokus i timen eller leksa. For å opprette ei oppgåve legger du til ønskja innstillingar nedanfor og trykkjer på «Lagre oppgåva». Elevane finn oppgåva i Oppdragstavla inne i spelet. Alle felt er obligatoriske. Du må også leggje til eit matteoppdrag for å lagre oppgåva.'
    },
    edit: {
      title: 'Rediger «{{ name }}»',
      subtitle:
        'Her visast innstillingar for {{ name }}. Du kan endre alle innstillingane og leggje til eller fjerne oppdrag. Hugs at alle felt er obligatoriske.'
    }
  },
  library: {
    title: 'Bibliotek',
    subTitle:
      'Få ei oversikt over alle matteoppdraga i Numetry. Filtrer etter matematiske tema eller søk etter oppdrag. Trykk på «Meir info» for å lese om innhaldet i dei ulike modusane og den faglege bakgrunnen for oppdraget.'
  },
  reports: {
    title: 'Rapportar',
    subtitle:
      'Her visast rapportar frå oppgåver du har tildelt elevar og grupper. Trykk deg inn på rapporten for å sjå ei oversikt over elevanes framgang, poeng, tid brukt og antal gale svar.'
  },
  offlineContent: {
    title: 'Oppdragsark',
    titleMobile: 'Innhold',
    subtitle:
      'Søk og last ned ferdiglagde arbeidsark basert på Numetrys spillmoduser.'
  },
  pdfGenerator: {
    title: 'PDF-generatorar',
    subtitle:
      'Her kan du prøve vårt nye verktøy for å lage egne arbeidsark. Velg regneart og innstillinger for arbeidsarket ditt. Se forhåndsvisning av arket mens du lager det. Når du er fornøyd, trykk «Lag arbeidsark» for å laste ned eller skrive ut arket.',
    generators: {
      multiplication: {
        title: 'Multiplikasjonsgenerator',

        breadcrumb: {
          title: 'Multiplikasjon'
        }
      },
      division: {
        title: 'Divisjonsgenerator',
        breadcrumb: {
          title: 'Divisjon'
        }
      },
      additionAndSubtraction: {
        title: 'Addisjons- og subtraksjonsgenerator',
        subtitle:
          'Her kan du lage egne arbeidsark. Velg regneart og innstillinger for arbeidsarket ditt. Se forhåndsvisning av arket mens du lager det. Når du er fornøyd, trykk «Lag arbeidsark» for å laste ned eller skrive ut arket.',
        breadcrumb: {
          title: 'Addisjon og subtraksjon'
        }
      },
      fraction: {
        title: 'Fraksjonsgenerator',
        breadcrumb: {
          title: 'Fraksjon'
        }
      }
    }
  },
  groups: {
    title: 'Grupper',
    subtitle:
      'Her visast alle grupper frå Feide som er kopla til kontoen din. Du kan også opprette eigne grupper. Du kan velje å skjule Feide-grupper du ikkje ønskjer å sjå i Lærarportalen, eller slette sjølvlaga grupper. Trykk på brytaren merkt «Skjulte grupper» for å få sjå og opprette Feide-grupper du tidlegare har gjømt.'
  },
  license: {
    title: 'Lisensar',
    tabs: {
      manage: {
        title: 'Lisensbehandling',
        subtitle: 'Dette er en oversikt over alle dine lisensar',
        allSchools: 'Alle skoler'
      },
      buy: {
        title: 'Bestill lisensar'
      }
    }
  },
  helpAndSupport: {
    title: 'Brukarstøtte',
    subtitle:
      'Numetry sin kundeservice kan hjelpe deg om du har spørsmål eller opplever problemer. Her finner du kundeservice sine åpningstider og kontaktinformasjon. Utenfor våre åpningstider finner du svar på ofte stilte spørsmål i hjelpesenteret vårt.'
  },
  settings: {
    title: 'Kontoinnstillingar'
  },
  customerOverview: {
    title: 'Kundeoversikt',
    subtitle: 'Dette er en oversikt over alle dine avtaler'
  },
  feedback: {
    title: 'Gi oss ris og ros',
    popover: {
      title: 'Hva synes du om Numetry Skole?',
      input: {
        placeholder: 'Utdyp gjerne'
      },
      moods: {
        bad: 'Dårlig',
        dissatisfied: 'Misfornøyd',
        neutral: 'Nøytral',
        satisfied: 'Fornøyd',
        verySatisfied: 'Veldig fornøyd'
      },
      button: {
        text: 'Send inn'
      },
      success: {
        title: 'Tusen takk!'
      }
    }
  }
}

export default navigationLocaleNo
