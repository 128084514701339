import { NavigationLocaleTypes } from '../locales.types'

const navigationLocaleNb: NavigationLocaleTypes = {
  mainMenu: {
    title: 'Hovedmeny',
    titleMobile: 'Mer'
  },
  menu: {
    title: 'Meny'
  },
  assignments: {
    title: 'Mine oppgaver​',
    subtitle:
      'Her vises en oversikt over alle oppgavene du har opprettet. Statusen viser om oppgaven er planlagt, aktiv eller utløpt. En planlagt oppgave er enda ikke synlig i oppdragstavlen i spillet. Du kan søke etter navn på oppgaver, filtrere etter status og sortere ut fra kolonnene. Trykk «Rediger» for å gjøre endringer på en oppgave.',
    newTitle: 'Lag en ny oppgave',
    components: {
      contentModal: {
        subtitle:
          'Velg nye oppdrag å legge til i oppgaven. Finn ut mer om hvert spill ved å klikke på <strong>«Mer info»</strong> på kortet. Du kan også begynne å lage en oppgave ved å velge spill nedenfor.'
      }
    },
    create: {
      title: 'Lag en oppgave',
      subtitle:
        'En oppgave er en samling av matteoppdrag som du deler ut til elevene med det som skal være fokus i timen eller i leksen. For å opprette en oppgave legger du til ønskede innstillinger nedenfor og trykker på «Lagre oppgaven». Elevene finner oppgaven i Oppdragstavlen inne i spillet. Alle felter er obligatoriske. Du må også legge til et matteoppdrag for å lagre oppgaven.'
    },
    edit: {
      title: 'Rediger «{{ name }}»',
      subtitle:
        'Her vises innstillinger for {{ name }}. Du kan endre alle innstillingene og legge til eller fjerne oppdrag. Husk at alle felt er obligatoriske.'
    }
  },
  library: {
    title: 'Bibliotek',
    subTitle:
      'Få en oversikt over alle matteoppdragene i Numetry. Filtrer etter matematiske tema eller søk etter oppdrag. Trykk på «Mer info» for å lese om innholdet i de ulike modusene og den faglige bakgrunnen for oppdraget. '
  },
  reports: {
    title: 'Rapporter',
    subtitle:
      'Her vises rapporter fra oppgaver du har tildelt elever eller grupper. Trykk deg inn på rapporten for å se en oversikt over elevenes fremgang, poeng, tid brukt og antall gale svar.'
  },
  offlineContent: {
    title: 'Oppdragsark',
    titleMobile: 'Innhold',
    subtitle:
      'Søk og last ned ferdiglagde arbeidsark basert på Numetrys spillmoduser.'
  },
  pdfGenerator: {
    title: 'PDF-generatorer',
    subtitle:
      'Her kan du prøve vårt nye verktøy for å lage egne arbeidsark. Velg regneart og innstillinger for arbeidsarket ditt. Se forhåndsvisning av arket mens du lager det. Når du er fornøyd, trykk «Lag arbeidsark» for å laste ned eller skrive ut arket.',
    generators: {
      multiplication: {
        title: 'Multiplikasjonsgenerator',
        breadcrumb: {
          title: 'Multiplikasjon'
        }
      },
      division: {
        title: 'Divisjonsgenerator',
        breadcrumb: {
          title: 'Divisjon'
        }
      },
      additionAndSubtraction: {
        title: 'Addisjons- og subtraksjonsgenerator',
        subtitle: `Here you can create your own worksheets. Choose the calculation type and settings for your worksheet. Preview the sheet as you create it. When you're satisfied, press "Create Worksheet" to download or print the worksheet.`,
        breadcrumb: {
          title: 'Addisjon og subtraksjon'
        }
      },
      fraction: {
        title: 'Fraksjonsgenerator',
        breadcrumb: {
          title: 'Fraksjon'
        }
      }
    }
  },
  groups: {
    title: 'Grupper',
    subtitle:
      'Her vises alle grupper fra Feide som er tilknyttet kontoen din. Du kan også opprette egne grupper. Du kan velge å skjule Feide-grupper du ikke ønsker å se i Lærerportalen, eller slette selvlagde grupper. Trykk på bryteren merket «Skjulte grupper» for å se og gjenoprette Feide-grupper du tidligere har gjemt. '
  },
  license: {
    title: 'Lisenser',
    tabs: {
      manage: {
        title: 'Lisensbehandling',
        subtitle: 'Dette er en oversikt over alle dine lisensar',
        allSchools: 'Alle skoler'
      },
      buy: {
        title: 'Bestill lisenser'
      }
    }
  },
  helpAndSupport: {
    title: 'Brukerstøtte',
    subtitle:
      'Numetry sin kundeservice kan hjelpe deg om du har spørsmål eller opplever problemer. Her finner du kundeservice sine åpningstider og kontaktinformasjon. Utenfor våre åpningstider finner du svar på ofte stilte spørsmål i hjelpesenteret vårt.'
  },
  settings: {
    title: 'Kontoinnstillinger'
  },
  customerOverview: {
    title: 'Kundeoversikt',
    subtitle: 'Dette er en oversikt over alle dine avtaler'
  },
  feedback: {
    title: 'Gi oss ris og ros',
    popover: {
      title: 'Hva synes du om Numetry Skole?',
      input: {
        placeholder: 'Utdyp gjerne'
      },
      moods: {
        bad: 'Dårlig',
        dissatisfied: 'Misfornøyd',
        neutral: 'Nøytral',
        satisfied: 'Fornøyd',
        verySatisfied: 'Veldig fornøyd'
      },
      button: {
        text: 'Send inn'
      },
      success: {
        title: 'Tusen takk!'
      }
    }
  }
}

export default navigationLocaleNb
